import { useMutation } from '@tanstack/react-query';
//types

// services
import { POST_INVENTORY_FUll } from 'api/inventory';

export const useMutationInventoryFull = () => {
  const mutationInfo = useMutation(POST_INVENTORY_FUll, {
    // onSuccess: (data, variables, context) => {
    //   // After success call
    //   return data;
    // },
    onError: (err: Error) => {
      // After error call
      const message = err ? err?.message : 'Error while fetching Profile';
      console.error(message);
      return message;
    },

    onSettled: (data, error, variables, context) => {
      // After error or success call... doesn't matter!
    },
  });
  const responseData = mutationInfo.data;

  return {
    ...mutationInfo,
    inventoryItems: responseData?.data.length ? responseData.data : [],
    inventoryMeta: responseData?.meta ? responseData.meta : {},
  };
};
