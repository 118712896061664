import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { MEDIA } from 'utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWindowSize } from 'react-use';
// locale

// store
import { useAppStore, useInventoryStore } from 'store';

// types
import { EGridType } from 'types/units';
import { EProductCardType } from '../../../../../components/features/Cards/ProductCard/type';
// components
import { ProductCard } from 'components/features';

// styles
import './index.scss';

import { AppLoader } from 'components/atoms';
import { ScrollToTop } from '../../../../../components/atoms/ScrollToTop';

//data
const InventoryScrollable = ({ inventoryItems }) => {
  const { inventoryGrid } = useInventoryStore();
  const { gameType } = useAppStore();
  const { width } = useWindowSize();
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const scrollableRef = useRef<any>(null);

  const container = document.getElementById('scrollableDiv');
  function updateScrollState() {
    if (container) {
      const containerHeight = container.clientHeight;
      const contentHeight = container.scrollHeight;
      const scrollPosition = container.scrollTop;
      const scrolledHalf =
        scrollPosition > (contentHeight - containerHeight) / 4;
      setShowScrollButton(scrolledHalf);
    }
  }
  if (container) {
    container.addEventListener('scroll', updateScrollState);
  }
  useEffect(() => {
    if (container) {
      return () => {
        container.removeEventListener('scroll', updateScrollState);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ProductItems = useMemo(() => {
    return (
      <>
        {inventoryItems.map(item => (
          <Fragment key={item.id}>
            <ProductCard
              cardType={EProductCardType.Inventory}
              gameType={gameType}
              size={inventoryGrid}
              item={item}
              isInventory={true}
            />
          </Fragment>
        ))}
      </>
    );
  }, [gameType, inventoryGrid, inventoryItems]);

  return (
    <div
      className="inventory-scrollable"
      id="scrollableDiv"
      ref={scrollableRef}
    >
      <div
        className={
          'inventory-scrollable__grid' +
          (inventoryGrid === EGridType.Small && width > MEDIA.M1024
            ? ' inventory-scrollable__compact'
            : '')
        }
      >
        {ProductItems}
      </div>

      <ScrollToTop
        customShow
        customShowFlag={showScrollButton}
        customSelector={'scrollableDiv'}
      />
    </div>
  );
};

export { InventoryScrollable };
